.slideshow {
    width: 100%;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    transition: transform 1s ease-in-out;
    justify-content: space-between;
  }
  
  .carousel img {
    width: 15%;
    height: auto;
  }
  
  .carousel img.active {
    flex: 0 0 auto;
  }
  
.slider-logo{
  height: 43px;
  width: 181px;
}

@media (max-width: 400px) {
  .slider-logo{
    height: 25px;
    width: 181px;
  }
}

  