.slideshow-container {
    overflow: hidden;
    width: 100%;
    /* height: 300px; */
    height: 100%;
    position: relative;
  }
  
  .slide {
    display: flex;
    transition: transform 1s ease;
  }
  
  .slide img {
    width: 100%;
    height: auto;
  }