.rounder_container {
    height: 600px;
    margin: 0;
    display: grid;
    grid-template-rows: 500px 100px;
    grid-template-columns: 1fr 30px 30px 30px 30px 30px 1fr;
    align-items: center;
    justify-items: center;
}

main#carousel {
    grid-row: 1 / 2;
    grid-column: 1 / 8;
    width: 100vw;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 600px;
    --items: 5;
    --middle: 3;
    --position: 1;
    pointer-events: none;
}

div.item {
    position: absolute;
    /* width: 300px; */
    width: 40%;
    /* height: 400px; */
    /* background-color: coral; */
    --r: calc(var(--position) - var(--offset));
    --abs: max(calc(var(--r) * -1), var(--r));
    transition: all 0.25s linear;
    transform: rotateY(calc(-10deg * var(--r))) translateX(calc(-300px * var(--r)));
    z-index: calc((var(--position) - var(--abs)));
    
}

/* div.item:nth-of-type(n) {
    --offset: calc(n + 1);
}

input:nth-of-type(n) {
    grid-column: calc(n + 1) / calc(n + 2);
    grid-row: 2 / 3;
}

input:nth-of-type(n):checked ~ main#carousel {
    --position: calc(n + 1);
} */


div.item:nth-of-type(1) {
    --offset: 1;
    background-color: #90f1ef;
}

div.item:nth-of-type(2) {
    --offset: 2;
    background-color: #ff70a6;
}

div.item:nth-of-type(3) {
    --offset: 3;
    background-color: #ff9770;
}

div.item:nth-of-type(4) {
    --offset: 4;
    background-color: #ffd670;
}

div.item:nth-of-type(5) {
    --offset: 5;
    background-color: #e9ff70;
}

div.item:nth-of-type(6) {
    --offset: 6;
    background-color: #e9ff70;
}

div.item:nth-of-type(7) {
    --offset: 7;
    background-color: #e9ff70;
}

input:nth-of-type(1) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

input:nth-of-type(1):checked~main#carousel {
    --position: 1;
}

input:nth-of-type(2) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}

input:nth-of-type(2):checked~main#carousel {
    --position: 2;
}

input:nth-of-type(3) {
    grid-column: 4 /5;
    grid-row: 2 / 3;
}

input:nth-of-type(3):checked~main#carousel {
    --position: 3;
}

input:nth-of-type(4) {
    grid-column: 5 / 6;
    grid-row: 2 / 3;
}

input:nth-of-type(4):checked~main#carousel {
    --position: 4;
}

input:nth-of-type(5) {
    grid-column: 6 / 7;
    grid-row: 2 / 3;
}

input:nth-of-type(5):checked~main#carousel {
    --position: 5;
}

input:nth-of-type(6) {
    grid-column: 7 / 8;
    grid-row: 2 / 3;
}

input:nth-of-type(6):checked~main#carousel {
    --position: 6;
}

input:nth-of-type(7) {
    grid-column: 8/ 9;
    grid-row: 2 / 3;
}

input:nth-of-type(7):checked~main#carousel {
    --position: 7;
}