@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

/* * {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
  font-family: 'DM Sans', sans-serif;
  transition: background .4s ease-in;
  background-color: #c394f8;
  
  &.blue {
    background-color: #428aa6;
  }
} */

.three_slider_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
  font-family: 'DM Sans', sans-serif;
  transition: background .4s ease-in;
  background-color: white;

  &.blue {
    background-color: #428aa6;
  }
}

input[type=radio] {
  display: none;
}

.card {
  position: absolute;
  width: 80%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform .4s ease;
  cursor: pointer;
  border-radius: 10px;
  ;
}

.container {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  /* height: 100%; */
  height: 400px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

.cards {
  /* position: relative; */
  width: 100%;
  height: 100%;
  /* height: auto; */
  margin-bottom: 20px;
}

/* img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
} */

.card_img {
  width: 100%;
  /* height: 100%; */
  border-radius: 10px;
  object-fit: cover;
}

#item-1:checked~.cards #song-3,
#item-2:checked~.cards #song-1,
#item-3:checked~.cards #song-2 {
  transform: translatex(-40%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1:checked~.cards #song-2,
#item-2:checked~.cards #song-3,
#item-3:checked~.cards #song-1 {
  transform: translatex(40%) scale(.8);
  opacity: .4;
  z-index: 0;
}

#item-1:checked~.cards #song-1,
#item-2:checked~.cards #song-2,
#item-3:checked~.cards #song-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;

  img {
    box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
  }
}

.player {
  background-color: #fff;
  border-radius: 8px;
  min-width: 320px;
  padding: 16px 10px;
}

.upper-part {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 36px;
  overflow: hidden;
}

.play-icon {
  margin-right: 10px;
}

.song-info {
  width: calc(100% - 32px);
  display: block;
}

.song-info .title {
  color: #403d40;
  font-size: 14px;
  line-height: 24px;
}

.sub-line {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.subtitle,
.time {
  font-size: 12px;
  line-height: 16px;
  color: #c6c5c6;
}

.time {
  font-size: 12px;
  line-height: 16px;
  color: #a5a5a5;
  font-weight: 500;
  margin-left: auto;
}

.progress-bar {
  height: 3px;
  width: 100%;
  background-color: #e9efff;
  border-radius: 2px;
  overflow: hidden;
}

.progress {
  display: block;
  position: relative;
  width: 60%;
  height: 100%;
  background-color: #2992dc;
  border-radius: 6px;
}

.info-area {
  width: 100%;
  position: absolute;
  top: 0;
  left: 30px;
  transition: transform .4s ease-in;
}

#item-2:checked~.player #test {
  transform: translateY(0);
}

#item-2:checked~.player #test {
  transform: translateY(-40px);
}

#item-3:checked~.player #test {
  transform: translateY(-80px);
}

@media (max-width: 767px) {
  .container {
    height: 300px;
    /* Adjust height for tablets */
    max-height: 500px;
  }
}

/* Media query for screens smaller than 576px (phones) */
@media (max-width: 575px) {
  .container {
    height: 200px;
    /* Adjust height for phones */
    max-height: 200px !important;
  }

  .cards {
    /* position: relative; */
    width: 100%;
    height: 50%;
    /* height: auto; */
    margin-bottom: 0;
  }

  .three_slider_container {
    padding: 0px 10px;
  }
}