.container {
    position: relative;
    max-width: 1000px;
    margin: auto;
}

.mySlides {
    display: hidden;
    /* border: 2px solid blue; */

}


.cursor {
    cursor: pointer;
}

.prev,
.next {
    cursor: pointer;
    /* position: absolute; */
    /* top: 40%; */
    width: auto ;
    /* padding: 16px; */
    /* margin-top: -50px; */
    /* color: green; */
    font-weight: bold;
    /* font-size: 20px; */
    /* border-radius: 0 3px 3px 0; */
    user-select: none;
    -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
    /* background-color: rgba(0, 0, 0, 0.8); */
    fill: #F6821F !important;
    /* border: 1px solid #F6821F; */
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* Container for image text */
.caption-container {
    text-align: center;
    background-color: #222;
    padding: 2px 16px;
    color: white;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Six columns side by side */
.column {
    float: left;
    /* width: 10.66%; */
}

/* Add a transparency effect for thumnbail images */
.demo {
    opacity: 0.6;
    background-color: lightgray;
    border-radius: 50%;
    
}

.active,
.demo:hover{
    opacity: 1;
    background-color: #F6821F;
    border-radius: 50%;
    padding: 3px;
    /* border:2px solid green; */
}

.gallery-container {
    display: flex;
    justify-content: center;
}

.image-container_success {
    /* display: flex;
    flex-wrap: wrap; */
    display: flex;
    /* width: 80%; */
    /* position: relative; */
    /* border: 2px solid green; */
    margin: auto;
    transition: ease-in-out 0.5s ease;
    justify-content: center;
    align-items: center;
}

.image-item {
    width: 200px;
    /* Adjust the width as needed */
    margin: 10px;
    cursor: pointer;
}

.image-item img {
    width: 100%;
    height: auto;
}

/* .image-item.active {
    transform: scale(1.2);
} */